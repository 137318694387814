// import { Environment } from './environment.model';
// // import * as data from '../assets/config.json';
// var data = require('../assets/config.json');

// export const environment: Environment = <any>data;
export const environment = {
    production: true,
    date: "MM/DD/YYYY",
    dbDate: "MM/DD/YYYY"
};
