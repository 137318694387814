import './lib'
import './lib'

import { enableProdMode } from '@angular/core';

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
fetch('/assets/config.json?v=' + new Date().getTime() + Math.random()).then(resp => resp.json()).then(config => {
  window['firebase_config'] = config.firebase;
  window['config'] = config;
  import('./app/app.module').then(module => {
    platformBrowserDynamic().bootstrapModule(module.AppModule).catch(err => console.error(err));
  });
  // import { AppModule } from './app/app.module';
});
// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.log(err));
